import React from 'react';
import { Alert } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Page from '@shared/Page';

interface Props {
  message: string;
}

export default function HomePage({ message }: Props) {
  return (
    <Page title='Home Page'>
      <Alert color='success' icon={<CheckIcon fontSize='inherit' />}>
        {message}
      </Alert>
    </Page>
  );
}
