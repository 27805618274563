import React from 'react';
import { createRoot } from 'react-dom/client';

// temporarily using Roboto, may switch to another font to match HS
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import HomePage from '@home/HomePage';

const appRoot = document.getElementById('indexRoot');

if (!appRoot) {
  throw new Error('Unable to find application root in order to mount React!');
}

const root = createRoot(appRoot);

interface HomePageData {
  message: string;
}

declare global {
  interface Window {
    INITIAL_DATA_HOME_ID: HomePageData;
  }
}

const pageData = (window.INITIAL_DATA_HOME_ID ?? {}) as HomePageData;

root.render(
  <HomePage
    message={
      pageData?.message ||
      'I did not get the expected initial message from the server'
    }
  />,
);
